import React from 'react';
import '../../App.css';
import ImageUpload from '../ImageUpload';


function Services() {
  return (
    <>
    {/* <h1 className='services'>PREDICT</h1>; */}
  <ImageUpload />
  
  </>
  )
  
}
export default Services;

