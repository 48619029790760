// MarketPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './MarketPage.css';

export default function MarketPage() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const products = {
    Vegetable: [
      { name: 'Tomato', imgSrc: '/images/tomato.png', link: '/products/tomato' },
      { name: 'Potato Grain', imgSrc: '/images/potato_leaf.png', link: '/products/potato_leaf' },
      { name: 'Cassava Leaf', imgSrc: '/images/cassava_leaf.png', link: '/products/cassava_leaf' },
      { name: 'Cabbage', imgSrc: '/images/cabbage.png', link: '/products/cabbage' },
      { name: 'Lettuce', imgSrc: '/images/lettuce.png', link: '/products/lettuce' }
    ],
    Grain: [
      { name: 'Rice', imgSrc: '/images/rice.png', link: '/products/rice' },
      { name: 'Wheat', imgSrc: '/images/wheat.png', link: '/products/wheat' },
      { name: 'Corn', imgSrc: '/images/corn.png', link: '/products/corn' },
      { name: 'Barley', imgSrc: '/images/barley.png', link: '/products/barley' }
    ],
    Plantain: [
      { name: 'Ripe Plantain', imgSrc: '/images/ripe.png', link: '/products/ripe-plantain' },
      { name: 'Green Plantain', imgSrc: '/images/gp.png', link: '/products/green-plantain' },
      { name: 'Banana', imgSrc: '/images/ban.png', link: '/products/banana' }
    ],
    Cassava: [
      { name: 'Cassava Root', imgSrc: '/images/croot.png', link: '/products/cassava-root' },
      { name: 'Cassava Leaf', imgSrc: '/images/cassava_leaf.png', link: '/products/cassava-leaf' },
      { name: 'Fufu', imgSrc: '/images/fufu.png', link: '/products/fufu' },
      { name: 'Gari', imgSrc: '/images/gari.png', link: '/products/gari' }
    ]
  };

  const handleButtonClick = (productType) => {
    setSelectedProduct(productType);
  };

  return (
    <div className='market-page'>
      <h1>SELECT A PRODUCT TO BUY/SELL</h1>
      <div className='button-container'>
        <button onClick={() => handleButtonClick('Vegetable')}>
          <img src="/images/vegetable.png" alt="Vegetable" />
          Vegetable
        </button>
        <button onClick={() => handleButtonClick('Grain')}>
          <img src="/images/grain.png" alt="Grain" />
          Grain
        </button>
        <button onClick={() => handleButtonClick('Plantain')}>
          <img src="/images/plantain.png" alt="Plantain" />
          Plantain
        </button>
        <button onClick={() => handleButtonClick('Cassava')}>
          <img src="/images/cassava.png" alt="Cassava" />
          Cassava
        </button>
      </div>

      {selectedProduct && (
        <div className='product-list'>
          <h2>{selectedProduct} Options</h2>
          <div className='product-items'>
            {products[selectedProduct].map((item, index) => (
              <div key={index} className='product-item'>
                <Link to={item.link}>
                  <img src={item.imgSrc} alt={item.name} className='product-image' />
                </Link>
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className='talk-div'>
        <p>CAN'T FIND THE PRODUCT YOU ARE LOOKING FOR?</p>
        <button className='talk-btn'>TALK TO US</button>
      </div>
    </div>
  );
}
