import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { decodeJWT } from "../Utils/token";


const PrivateRoute = ({ component: Component, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        const isValidToken = () => {
            if (token) {
                const decoded = decodeJWT(token);
                const now = Date.now() / 1000; // Current time in seconds
                return decoded.exp > now;
            }
            return false;
        };

        // Check if token is valid when the component mounts
        setIsAuthenticated(isValidToken());
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default PrivateRoute;
