import { useState, useEffect } from "react";
import {useCallback} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Paper, CardActionArea, CardMedia, Grid, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, CircularProgress } from "@material-ui/core";
// import agro2 from "./agro2.png";
// import image from "./crop.jpg";
import { DropzoneArea } from 'material-ui-dropzone';
import { blue, common } from '@material-ui/core/colors';
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import {getTreatmentsByClass} from './Therapy.js';
import BlueButton from './Bluebutton.js';
import Therapydetails from "./pages/Therapydetails.js";
import axios from "axios";


const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(common.white),
    backgroundColor: common.white,
    '&:hover': {
      backgroundColor: '#ffffff7a',
    },
  },
}))(Button);
// const axios = require("axios").default;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  clearButton: {
  padding:'22'
  },
  root: {
    maxWidth: 345,
    flexGrow: 1,
  },
  media: {
    height: 400,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  gridContainer: {
    justifyContent: "center",
    padding: "4em 1em 0 1em",
  
  },
  mainContainer: {
    backgroundImage: `url("/images/hs4.png")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: "97vh",
    marginTop: "8px",
    // backgroundColor: 'blue'

  },
  imageCard: {
    margin: "auto",
    maxWidth: 400,
    height: "500px",
    backgroundColor: ' rgba(255, 255, 255, 0.85)',
    boxShadow: '0px 9px 70px 0px rgb(0 0 0 / 40%) !important',
    borderRadius: '15px',
    marginTop: "-45px",

  },
  imageCardEmpty: {
    height: 'auto',
  },
  noImage: {
    margin: "auto",
    width: 400,
    height: "400 !important",
  },
  input: {
    display: 'none',
  },
  uploadIcon: {
    background: 'white',
  },
  tableContainer: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
  },
  table: {
    backgroundColor: 'transparent !important',
  },
  tableHead: {
    backgroundColor: 'transparent !important',
  },
  tableRow: {
    backgroundColor: 'transparent !important',
  },
  tableCell: {
    fontSize: '22px',
    backgroundColor: 'transparent !important',
    borderColor: 'transparent !important',
    color: '#000000a6 !important',
    fontWeight: 'bolder',
    padding: '1px 24px 1px 16px',
  },
  tableCell1: {
    fontSize: '14px',
    backgroundColor: 'transparent !important',
    borderColor: 'transparent !important',
    color: '#000000a6 !important',
    fontWeight: 'bolder',
    padding: '1px 24px 1px 16px',
  },
  tableBody: {
    backgroundColor: 'transparent !important',
  },
  text: {
    color: 'white !important',
    textAlign: 'center',

  },
  buttonGrid: {
    maxWidth: "416px",
    width: "100%",
    marginLeft: "40px",
  },
  detail: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },

  loader: {
    color: '#be6a77 !important',
  }
}));
export const ImageUpload = () => {
  
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [data, setData] = useState();
  const [datat, setDatat] = useState(false);
  const [image, setImage] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  let confidence = 0;

  const sendFile = useCallback(async () => {
    if (image) {
      let formData = new FormData();
      formData.append("file", selectedFile);
      let res = await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL,
        data: formData,
      });
      if (res.status === 200) {
        setData(res.data);
      }
      setIsloading(false);
    }
  }, [image, selectedFile]);

  // const sendFile = useCallback(async () => {
  //   if (image) {
  //     setIsloading(true);
  //     try {
  //       // Simulate API call delay
  //       await new Promise(resolve => setTimeout(resolve, 2000));
  //       // Mock response
  //       setData({
  //         results: [
  //           {
  //             class: "cat",
  //             confidence: 0.9
  //           },
  //           {
  //             class: "dog",
  //             confidence: 0.1
  //           }
  //         ]
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //     setIsloading(false);
  //   }
  // }, [image]);

  const clearData = () => {
    setData(null);
    setDatat(false);
    setImage(false);
    setSelectedFile(null);
    setPreview(null);
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
  }, [selectedFile]);

      
      useEffect(() => {
    if (!preview) {
      return;  
    }
    setIsloading(true);
    sendFile();
    }, [preview, sendFile, selectedFile]);

  const onSelectFile = (files) => {
    if (!files || files.length === 0) {
      setSelectedFile(undefined);
      setImage(false);
      setData(undefined);
      return;
    }
    setSelectedFile(files[0]);
    setData(undefined);
    setImage(true);
  };

  if (data) {
    confidence = (parseFloat(data.confidence) * 100).toFixed(2);
  }


  
  const fetchTreatment = async (key) => {

    try {
      const res = await getTreatmentsByClass(key);
      // const dat = await res.json();
      setDatat({
        results: [
          res[0]
        ]

      });

      
      console.log(res[0].class);
      console.log(res[0]["treatment"]);
    } catch (error) {
      console.error(error);
    }
 
  };
  
  

  
  const renderTreatment = () => {
   
    if (datat) {
      return (
        <CardContent>
          {datat.results.map((result, index) => (
            <>
            <Typography key={index}>
              {result.class}: {result.treatment}
              <Therapydetails />
            </Typography>
            
            </>
          ))}
        </CardContent>
      );
    } else {
      return null;
    }
  };

  const handleClick = async () =>{
    setDatat(true)
  }



  const renderDetails = async () => {
    setDatat(undefined)
    if (data) {
      
      return (
        <CardContent>
          {data.results.map((result, index) => (
            <Typography key={index}>
              {result.class}: {result.confidence.toFixed(2)}
            </Typography>

          ))}
        </CardContent>
      );
    } else {
      return null;
    }
  };
  



  return (
    <React.Fragment>
      <Container maxWidth={false} className={classes.mainContainer} disableGutters={true}>
        <Grid
          className={classes.gridContainer}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Card className={`${classes.imageCard} ${!image ? classes.imageCardEmpty : ''}`}>
              {image && <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={preview}
                  component="image"
                  title="Contemplative Reptile"
                />
              </CardActionArea>
              }
              {datat!==null?renderTreatment():()=>renderDetails()}
              {!image && <CardContent className={classes.content}>
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={"It's Easy!! Drag/Drop or Upload an Image of a crop leaf to get insights"}
                  onChange={onSelectFile}
                />
              </CardContent>}
              {data && datat===false && <CardContent className={classes.detail}>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell1}>Label:</TableCell>
                        <TableCell align="right" className={classes.tableCell1}>Confidence:</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      <TableRow className={classes.tableRow}>
                        <TableCell component="th" scope="row" className={classes.tableCell}>
                          {data.class}
                        </TableCell>
                        <TableCell align="right" className={classes.tableCell}>{confidence}%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>}
              {data && datat===true && <CardContent className={classes.detail}>
                
              </CardContent>}
              {isLoading && <CardContent className={classes.detail}>
                <CircularProgress color="secondary" className={classes.loader} />
                <Typography className={classes.title} variant="h6" noWrap>
                  Processing
                </Typography>
              </CardContent>}
            </Card>
          </Grid>
          {data &&
            <Grid item container className={classes.buttonGrid} spacing={4}>
            <Grid item>
              <ColorButton variant="contained" className={classes.clearButton} color="primary" component="span" size="large" onClick={clearData} startIcon={<Clear fontSize="large" />}>
                Clear
              </ColorButton>
            </Grid>
            <Grid item>
              <ColorButton variant="contained" className={classes.clearButton} color="primary" component="span" size="large" onClick={()=>{fetchTreatment(data? data.class: "Potassium_Deficiency")}} startIcon={<Check fontSize="large" />}>
                Treatement
              </ColorButton>
            </Grid>
          </Grid>}
        </Grid >
      </Container >
     
    </React.Fragment >
  );
};

export default ImageUpload;