import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [language, setLanguage] = useState('EN'); // Default language

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    closeMobileMenu();
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src="/images/wheat.png" alt="Logo" className="navbar-logo-image" />
          Agroo AI
        </Link>

        {/* Language Dropdown */}
        <div className="language-dropdown">
          <select
            value={language}
            onChange={handleLanguageChange}
            className="language-select"
            aria-label="Select Language"
          >
            <option value="EN">English</option>
            <option value="FR">Français</option>
            <option value="ES">Español</option>
            <option value="DE">Deutsch</option>
          </select>
        </div>

        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>

        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/market" className="nav-links" onClick={closeMobileMenu}>
              Market
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/services" className="nav-links" onClick={closeMobileMenu}>
              Crop's Disease
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/products" className="nav-links" onClick={closeMobileMenu}>
              Products
            </Link>
          </li>
          {button && <Button buttonStyle="btn--outline">SIGN UP</Button>}
          <li className="nav-item">
            <Link
              to="/signup"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Register
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
