import React from 'react';
import '../../App.css';
import MarketPage from '../MarketPage';
// import '../MarketPage.css';


function Market() {
  return (
    
    <>
     
  
  <MarketPage />
  
  </>
  )
  
}
export default Market;

