import React from 'react';
import ProductPage from '../ProductPage';
import '../../App.css';

export default function Products() {
  return (
    
    <>
     
  
  <ProductPage />
  
  </>
  )
  
}


