// App.js
import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import Footer from './components/Footer';
import Therapydetails from './components/pages/Therapydetails';
import Login from "./components/pages/Login";
import Register from './components/RegisterPage';
import Market from './components/pages/market';
import ProductPage from './components/ProductPage';
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./components/Dashboard";

function ProductPageWrapper() {
  const { productName } = useParams();
  return <ProductPage product={productName} />;
}

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/signup' component={Register} />
          <Route path='/market' component={Market} />
          <Route path='/Therapydetails' component={Therapydetails} />
          <Route path='/products/:productName' component={ProductPageWrapper} />
          <Route path='/login' component={Login} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
