import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Discover the Benefits of Agroo A I for Modern Agriculture!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          
          {/* First Set of Cards */}
          <h1>Explore Our Crop Disease Diagnosis Tool, Market, Food Security, and More!</h1>
          <ul className='cards__items'>
            <CardItem
              src='images/crop_disease.png'
              text='Use Agroo A I to monitor crops and detect issues for increased yields and reduced costs'
              label='Detect Crop Disease'
              path='/services'
            />
            <CardItem
              src='images/market.png'
              text='Analyze soil conditions with Agroo A I for improved crop quality and reduced environmental impact'
              label='Explore the Market'
              path='/market'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/climate.png'
              text='Predict climate patterns and weather events with Agroo A I for increased efficiency and reduced risk of crop damage'
              label='Climate Prediction'
              path='/'
            />
            <CardItem
              src='images/pfarming.png'
              text='Optimize farming practices and improve efficiency with Agroo A I for increased yields and reduced costs'
              label='Precision Farming'
              path='/'
            />
            <CardItem
              src='images/food.png'
              text='Improve food safety with Agroo A I for increased consumer confidence and reduced risk of recalls'
              label='Food Safety'
              path=''
            />
          </ul>

          {/* Second Set of Cards (Same Images & Headings) */}
          <h1>Maximize Farming Efficiency with Agroo A I Drones!</h1>
          <ul className='cards__items'>
            {/* <CardItem
              videoSrc='/videos/fly.mp4'
              text='Use Agroo A I Drone Logistics to transport fresh produce from remote farm to mainland for efficient transportk'
              label='Drone Transport'
              path='/services'
            /> */}
            <CardItem
              videoSrc='/videos/drone.mp4'
              text='Use Agroo A I to monitor crops and detect issues for increased yields and reduced costs'
              label='Crop Monitoring'
              path='/'
            />
            <CardItem
              videoSrc='/videos/spray.mp4'
              text='Use Agroo A I Spray Drone to Spray Fertilizer on Crops'
              label='Spraying fertilizer'
              path='/'
            />
          </ul>
          
          

          {/* Third Set of Cards (Same Images & Headings) */}
          <h1>Enhance Soil Health and Climate Prediction with Agroo A I!</h1>
          <ul className='cards__items'>
            <CardItem
              src='/images/moist.png'
              text='Use Agroo A I Soil Moisture Sensor to Monitor Soil Moisture'
              label='Soil Analysis'
              path='/'
            />
            <CardItem
              src='images/soil.png'
              text='Analyze soil conditions with Agroo A I for improved crop quality and reduced environmental impact'
              label='Soil Testing'
              path='/'
            />
          </ul>
          {/* <ul className='cards__items'>
            <CardItem
              src='images/climate.png'
              text='Predict climate patterns and weather events with Agroo A I for increased efficiency and reduced risk of crop damage'
              label='Climate Prediction'
              path='/services'
            />
            <CardItem
              src='images/pfarming.png'
              text='Optimize farming practices and improve efficiency with Agroo A I for increased yields and reduced costs'
              label='Precision Farming'
              path='/products'
            />
            <CardItem
              src='images/food.png'
              text='Improve food safety with Agroo A I for increased consumer confidence and reduced risk of recalls'
              label='Food Safety'
              path='/sign-up'
            />
          </ul>

          {/* Fourth Set of Cards (Same Images & Headings) */}
          {/* <h1>Improve Food Safety and Boost Agricultural Productivity!</h1>
          <ul className='cards__items'>
            <CardItem
              src='images/moist.png'
              text='Use Agroo A I to monitor crops and detect issues for increased yields and reduced costs'
              label='Soil Analysis'
              path='/services'
            />
            <CardItem
              src='images/soil.png'
              text='Analyze soil conditions with Agroo A I for improved crop quality and reduced environmental impact'
              label='Soil Testing'
              path='/services'
            />
          </ul> */} 
          
        </div>
      </div>
    </div>
  );
}


export default Cards;
