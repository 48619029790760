import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      {/* <img 
        src='/images/hs3.png' 
        alt='Hero' 
        className='hero-image' 
      /> */}
      
      <h1>Welcome to Agroo A I</h1>
      <p><strong>COMING SOON!!</strong></p>
      <p>"Innovative Farm, Enhanced Trade"</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          GET STARTED
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
