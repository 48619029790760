import React, { useState } from "react";
import { useHistory } from "react-router-dom"; // If using React Router
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../App.css';

// Form Validation Schema using Yup
const validationSchema = Yup.object({
    name: Yup.string().required("Full Name is required."),
    email: Yup.string().email("Invalid email address").required("Email is required."),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required."),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required("Confirm Password is required."),
    role: Yup.string().required("Role is required."),
    contact_number: Yup.string(),
    address: Yup.string(),
});

const RegisterPage = ({ onFormSwitch }) => {
    const [error, setError] = useState('');
    const history = useHistory(); // For redirecting after successful registration
    

    const handleRegister = async (values) => {
        try {
            // Use environment variables for the API URLs
            // const registerUrl = `${process.env.REACT_APP_REGISTER_URL}`;
            // const loginUrl = `${process.env.REACT_APP_LOGIN_URL}`;

            const registerUrl = process.env.REACT_APP_REGISTER_URL || "http://localhost:8000/register";
            const loginUrl = process.env.REACT_APP_LOGIN_URL || "http://localhost:8000/login";


            // console.log("Register URL:", process.env.REACT_APP_REGISTER_URL);
            // console.log("Login URL:", process.env.REACT_APP_LOGIN_URL);

            


            // Make API call to register the user
            const response = await fetch(registerUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Registration Successful:", data);

                // After successful registration, attempt to login
                const loginResponse = await fetch(loginUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: values.email,
                        password: values.password,
                    }),
                });

                if (loginResponse.ok) {
                    const loginData = await loginResponse.json();
                    // Store the JWT token securely (localStorage for demo, but consider sessionStorage or Secure HttpOnly cookies)
                    localStorage.setItem("access_token", loginData.access_token);
                    // Redirect to a dashboard or home page
                    history.push("/login"); // Adjust with your actual path
                } else {
                    const loginError = await loginResponse.json();
                    setError(loginError.message || "Error logging in after registration.");
                }
            } else {
                const errorData = await response.json();
                setError(errorData.message || "Error registering user.");
            }
        } catch (err) {
            setError("Error registering. Please try again later.");
        }
    };

    return (
        <div className="RegisterPage-Container">
            <div className="auth-form-container">
                <h2>Register</h2>
                {error && <p className="error">{error}</p>}
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        password: '',
                        confirmPassword: '',
                        role: '',
                        contact_number: '',
                        address: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleRegister}
                >
                    <Form className="register-form">
                        <div>
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Full Name"
                                className="input-field"
                            />
                            <ErrorMessage name="name" component="div" className="error-message" />
                        </div>

                        <div>
                            <Field
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email or Phone Number"
                                className="input-field"
                            />
                            <ErrorMessage name="email" component="div" className="error-message" />
                        </div>

                        <div>
                            <Field
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                className="input-field"
                            />
                            <ErrorMessage name="password" component="div" className="error-message" />
                        </div>

                        <div>
                            <Field
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                className="input-field"
                            />
                            <ErrorMessage name="confirmPassword" component="div" className="error-message" />
                        </div>

                        <div>
                            <Field as="select" name="role" className="input-field">
                                <option value="">Register As</option>
                                <option value="Seller">Seller</option>
                                <option value="Buyer">Buyer</option>
                                <option value="Admin">Admin</option>
                            </Field>
                            <ErrorMessage name="role" component="div" className="error-message" />
                        </div>

                        <div>
                            <Field
                                type="text"
                                id="contact_number"
                                name="contact_number"
                                placeholder="Contact Number (Optional)"
                                className="input-field"
                            />
                            <ErrorMessage name="contact_number" component="div" className="error-message" />
                        </div>

                        <div>
                            <Field
                                as="textarea"
                                id="address"
                                name="address"
                                placeholder="Address (Optional)"
                                className="input-field"
                            />
                            <ErrorMessage name="address" component="div" className="error-message" />
                        </div>

                        <button className="loginbtn" type="submit">Register</button>
                    </Form>
                </Formik>

                <button className="link-btn" onClick={() => onFormSwitch('login')}>
                    Already have an account? Login here.
                </button>
            </div>
        </div>
    );
};

export default RegisterPage;
