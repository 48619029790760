import React, { useState } from 'react';
import './ProductPage.css';

export default function ProductPage({ product }) {
  const [formData, setFormData] = useState({
    productType: 'All',
    productStatus: 'All',
    variety: 'All',
    productionMode: 'All',
    packaging: 'All',
    pricePerUnit: '',
    totalWeight: '',
    certifications: 'All',
    finalDeliveryDate: '',
    logistics: 'All',
    countryDestination: 'All',
    countryOrigin: 'All',
    immediateDelivery: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSearch = () => {
    console.log('Searching with data:', formData);
  };

  const handleClear = () => {
    setFormData({
      productType: 'All',
      productStatus: 'All',
      variety: 'All',
      productionMode: 'All',
      packaging: 'All',
      pricePerUnit: '',
      totalWeight: '',
      certifications: 'All',
      finalDeliveryDate: '',
      logistics: 'All',
      countryDestination: 'All',
      countryOrigin: 'All',
      immediateDelivery: false,
    });
  };

  return (
    <div className="product-page">
      <div className="characteristics">
        <h2>Product Details</h2> {/* Added header here */}

        {/* Product Type Dropdown */}
        <label>
          Product Type
          <select name="productType" value={formData.productType} onChange={handleInputChange}>
            <option value="All">All</option>
            <option value="Vegetable">Vegetable</option>
            <option value="Fruit">Fruit</option>
            <option value="Grain">Grain</option>
            <option value="Root">Root</option>
          </select>
        </label>

        {/* Product Status Dropdown */}
        <label>
          Product Status
          <select name="productStatus" value={formData.productStatus} onChange={handleInputChange}>
            <option value="All">All</option>
            <option value="Fresh">Fresh</option>
            <option value="Frozen">Frozen</option>
            <option value="Dried">Dried</option>
          </select>
        </label>

        {/* Variety Dropdown */}
        <label>
          Variety
          <select name="variety" value={formData.variety} onChange={handleInputChange}>
            <option value="All">All</option>
            <option value="Roma">Roma</option>
            <option value="Nantes">Nantes</option>
            <option value="Carrot">Carrot</option>
          </select>
        </label>

        {/* Production Mode Dropdown */}
        <label>
          Production Mode
          <select name="productionMode" value={formData.productionMode} onChange={handleInputChange}>
            <option value="All">All</option>
            <option value="Organic">Organic</option>
            <option value="Conventional">Conventional</option>
          </select>
        </label>

        {/* Packaging Dropdown */}
        <label>
          Packaging for Shipment
          <select name="packaging" value={formData.packaging} onChange={handleInputChange}>
            <option value="All">All</option>
            <option value="Box">Box</option>
            <option value="Bag">Bag</option>
            <option value="Crate">Crate</option>
          </select>
        </label>

        {/* Certifications Dropdown */}
        <label>
          Certifications
          <select name="certifications" value={formData.certifications} onChange={handleInputChange}>
            <option value="All">All</option>
            <option value="ISO">ISO</option>
            <option value="Organic">Organic</option>
            <option value="Fair Trade">Fair Trade</option>
          </select>
        </label>

        {/* Logistics Dropdown */}
        <label>
          Logistics
          <select name="logistics" value={formData.logistics} onChange={handleInputChange}>
            <option value="All">All</option>
            <option value="Air">Air</option>
            <option value="Sea">Sea</option>
            <option value="Land">Land</option>
          </select>
        </label>

        {/* Country of Destination Dropdown */}
        <label>
          Country of Destination
          <select name="countryDestination" value={formData.countryDestination} onChange={handleInputChange}>
            <option value="All">All</option>
            <option value="USA">USA</option>
            <option value="Germany">Germany</option>
            <option value="China">China</option>
          </select>
        </label>

        {/* Country of Origin Dropdown */}
        <label>
          Country of Origin
          <select name="countryOrigin" value={formData.countryOrigin} onChange={handleInputChange}>
            <option value="All">All</option>
            <option value="Liberia">Liberia</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Ghana">Ghana</option>
          </select>
        </label>

        {/* Price per Weight Unit (Range) */}
        <label>
          Price per weight unit ($)
          <input
            type="range"
            name="pricePerUnit"
            min="0"
            max="100"
            value={formData.pricePerUnit}
            onChange={handleInputChange}
          />
          <input
            type="number"
            name="pricePerUnit"
            value={formData.pricePerUnit}
            onChange={handleInputChange}
          />
        </label>

        {/* Total Weight (Range) */}
        <label>
          Total Weight (kg)
          <input
            type="range"
            name="totalWeight"
            min="0"
            max="1000"
            value={formData.totalWeight}
            onChange={handleInputChange}
          />
          <input
            type="number"
            name="totalWeight"
            value={formData.totalWeight}
            onChange={handleInputChange}
          />
        </label>

        {/* Immediate Delivery Checkbox */}
        <label>
          <input
            type="checkbox"
            name="immediateDelivery"
            checked={formData.immediateDelivery}
            onChange={handleInputChange}
          />
          Immediate Delivery
        </label>

        {/* Final Delivery Date */}
        <label>
          Final Delivery Date
          <input
            type="date"
            name="finalDeliveryDate"
            value={formData.finalDeliveryDate}
            onChange={handleInputChange}
          />
        </label>

        {/* Search and Clear Buttons */}
        <div className="button-group">
      <button className="search-btn" onClick={handleSearch}>Search</button>
    <button className="clear-btn" onClick={handleClear}>Clear</button>
</div>

      </div>

      {/* Order Board */}
      <div className="order-board">
        <h2>Order Board</h2>
        <div className="results-header">
          <p>Results</p>
          <label>
            Show
            <select>
              <option>10</option>
              <option>20</option>
              <option>30</option>
            </select>
            Lines Per Page
          </label>
        </div>

        <table className="results-table">
          <thead>
            <tr>
              <th>Commodities</th>
              <th>Bid by Buyers</th>
              <th>Offer by Sellers</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Sample row */}
            <tr>
              <td>{product}</td>
              <td>$12</td>
              <td>$10</td>
              <td><button>Buy</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
