const TREATMENTS = [{
    "id": 1,
    "name": "Fungicide treatment",
    "treatment": "Spray affected plants with a fungicide to control the Alternaria Leaf Blight disease.",
    "class": "Alternaria_Leaf_Blight",
    "details":  "pray affected plants with a fungicide to control the Alternaria Leaf Blightpray affected plants with a fungicide to control the Alternaria Leaf Blight"
    },
    {
    "id": 2,
    "name": "Bactericide treatment",
    "treatment": "Spray affected plants with a bactericide to control the Potassium Deficiency disease.",
    "class": "Potassium_Deficiency",
    "details":  "pray affected plants with a fungicide to control the Alternaria Leaf Blightpray affected plants with a fungicide to control the Alternaria Leaf Blight"
    },
   
    {
    "id": 4,
    "name": "Healthy plants",
    "treatment": "Do Nothing.",
    "class": "Healthy",
    "details":  "Do nothing for Healthy"
    },
    {
    "id": 5,
    "name": "Crop rotation",
    "treatment": "Rotate crops to reduce the likelihood of the disease returning.",
    "class": "All diseases",
    "details":  "pray affected plants with a fungicide to control the Alternaria Leaf Blightpray affected plants with a fungicide to control the Alternaria Leaf Blight"
    },

    ];
    
    export function getTreatments() {
    return TREATMENTS;
    }
    
    export function getTreatment(id) {
    return TREATMENTS.find((treatment) => (treatment.id === id));
    }
    
    export function getTreatmentsByClass(dclass) {
    return TREATMENTS.filter((treatment) => (treatment.class === dclass));
    }