import React, { useState } from "react";
import '../App.css';
import { useHistory } from "react-router-dom"; // If using React Router

const LoginPage = (props) => {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [error, setError] = useState('');
    const history = useHistory(); // For redirecting after successful registration

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate fields
        if (!email || !pass) {
            setError("Please fill in all fields.");
            return;
        }

        try {
            const response = await fetch('http://localhost:8000/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password: pass }),
                credentials: 'include', // This will include the cookies (JWT token) in the request
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Login Successful:", data);
                // TODO: Redirect user to a dashboard or relevant page
                history.push("/dashboard");
            } else {
                const errorData = await response.json();
                setError(errorData.message || "Invalid login credentials.");
            }
        } catch (err) {
            setError("Error logging in. Please try again later.");
        }
    };

    return (
        <div className="LoginPage-Container">
            <div className="auth-form-container">
                <h2>Login</h2>
                {error && <p className="error">{error}</p>}
                <form className="login-form" onSubmit={handleSubmit}>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email or Phone Number"
                        id="email"
                        name="email"
                    />
                    <input
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        type="password"
                        placeholder="Password"
                        id="password"
                        name="password"
                    />
                    <button className="loginbtn" type="submit">Log In</button>
                </form>
                <button className="link-btn" onClick={() => props.onFormSwitch('register')}>
                    Don't have an account? Register here.
                </button>
            </div>
        </div>
    );
};

export default LoginPage;
