import React, { useEffect, useState } from 'react';
import '../../App.css';
import { useLocation } from 'react-router-dom';
import { getTreatmentsByClass } from '../Therapy.js';

export default function Therapydetails() {
    const location = useLocation();
    const myProp = location.state?.value || 'default value'; // Use a fallback if state or value is undefined

    console.log("The transferred prop is: ", myProp);

    // State to store treatment data
    const [treatment, setTreatment] = useState(null);

    useEffect(() => {
        // Function to fetch treatment data
        const fetchTreatment = async () => {
            try {
                const data = await getTreatmentsByClass(myProp);
                console.log("Treatment is: ", data);
                setTreatment(data[0]); // Assuming data[0] contains the treatment details
            } catch (error) {
                console.error("Error fetching treatment:", error);
            }
        };

        fetchTreatment();
    }, [myProp]); // This will run whenever `myProp` changes

    return (
        <h1 className='products'>
            {treatment ? treatment.details : "No treatment available"}
        </h1>
    );
}
